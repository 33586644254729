import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import favicon from "../assets/favicon.ico";
import Navigation from "./Navigation.js";
import layoutStyles from "./layout.module.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "description", content: "Homepage of Albert Liao" },
            { name: "keywords", content: "Albert, Liao" }
          ]}
          link={[
            {
              rel: "icon",
              type: "image/png",
              href: `${favicon}`,
              sizes: "32x32"
            }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className={layoutStyles.container}>
          <div className={layoutStyles.navigation}>
            <Navigation />
          </div>
          <div className={layoutStyles.main}>{children}</div>
        </div>
      </>
    )}
  />
);
