import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, Link, graphql } from "gatsby";

import avatar from "../assets/avatar.jpg";
import navigationStyles from "./navigation.module.css";

function NavBarLink(props) {
  return (
    <li className={navigationStyles.navBarLink}>
      <Link
        to={props.to}
        activeStyle={{
          textDecoration: "underline"
        }}
      >
        {props.children}
      </Link>
    </li>
  );
}

NavBarLink.propTypes = {
  to: PropTypes.string.isRequired
};

function Navigation(props) {
  return (
    <nav className={navigationStyles.navigation}>
      <div className={navigationStyles.profile}>
        <img className={navigationStyles.avatar} src={avatar} alt="avatar" />
        <StaticQuery
          query={graphql`
            query HeadingQuery {
              site {
                siteMetadata {
                  avatarText
                }
              }
            }
          `}
          render={data => (
            <Link to="/">
              <h4 className={navigationStyles.title}>
                {data.site.siteMetadata.avatarText}
              </h4>
            </Link>
          )}
        />
      </div>
      <ul className={navigationStyles.navBarList}>
        {/* <NavBarLink to="/">About</NavBarLink> */}
        {/* <NavBarLink to="/projects/">Projects</NavBarLink> */}
        {/* <NavBarLink to="/posts/">Posts</NavBarLink> */}
      </ul>
    </nav>
  );
}

export default Navigation;
