import React from "react";

import Layout from "../components/Layout.js";

export default () => {
  return (
    <Layout>
      <p>Coming soon...</p>
    </Layout>
  );
};
